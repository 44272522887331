import React from 'react'
import { graphql, Link } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'

// Components
import BlogListingComponent from '@components/BlogListingComponent'
import { Caption, VerticalHeading } from '@components/Typography'
import SectionComponent from '@components/SectionComponent'
import Grid from '@components/ViewGridComponent'
import SpacerComponent from '@components/SpacerComponent'
import { FadeIn } from '@components/Animations'

import { whiteTheme } from '../themes'

// Hooks
import useSetBackgroundColor from '@hooks/useSetBackgroundColor'
import SeoComponent from '@components/SeoComponent'

type BlogListingProps = {
  path: string
  uri: string
  params: object
  pageResources: any
  navigate: any
  data: any
  pageContext: {
    limit: number
    skip: number
    numPages: number
    currentPage: number
  }
}

const BlogListings: React.FC<BlogListingProps> = props => {
  const { nodes } = props.data.allContentfulBlog

  const extraPosts = [
    {
      title: 'How to Create an App',
      fullSlug: 'how-to-create-an-app',
      date: '10th January, 2021',
      image: {
        title: 'The Beginner’s Guide to Making an App',
        file: {
          url: 'https://images.unsplash.com/photo-1581287053822-fd7bf4f4bfec?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
        },
      },
    },
    {
      title: 'What is Tech for Good?',
      fullSlug: 'what-is-tech-for-good',
      date: '18th December, 2020',
      image: {
        title: 'What is Tech for Good?',
        file: {
          url: 'https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
        },
      },
    },
    {
      title: 'What is Co-Production?',
      fullSlug: 'what-is-co-production',
      date: '6th December, 2020',
      image: {
        title: 'What is co-production in tech?',
        file: {
          url: 'https://images.unsplash.com/photo-1586543354240-2187898bb2e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        },
      },
    },
  ]

  const { ref } = useSetBackgroundColor()

  const currentPage = props.pageContext.currentPage
  const numberOfPages = props.pageContext.numPages

  // insert the pillar pages after "We won!" blog post,
  // wherever that may be
  const feb2020BlogIndex = nodes
    .map((node: any) => node.date)
    .indexOf('22nd February, 2021')

  const shouldInsert = feb2020BlogIndex !== -1

  const posts = [...nodes]

  if (shouldInsert) {
    posts.splice(feb2020BlogIndex + 1, 0, ...extraPosts)
  }

  const pageTitle = `Latest Blogs ${
    currentPage !== 1 ? `| Page ${currentPage}` : ''
  }`

  return (
    <ThemeProvider theme={whiteTheme}>
      <SeoComponent
        title='A Tech Blog for Projects with Purpose'
        description='Rewriting the rules on how the world builds tech, one project at a time. Here’s our collection of resources for people looking to bring their idea to life. '
      />
      <SectionComponent ref={ref}>
        <Grid.Item
          columnStart={1}
          row={`1 / ${numberOfPages * 3}`}
          justifySelfStart
        >
          <FadeIn>
            <VerticalHeading>{pageTitle}</VerticalHeading>
          </FadeIn>
        </Grid.Item>
        {posts.map((node, index) => {
          const isLastItem = index === posts.length - 1
          return (
            <BlogListingComponent
              key={index}
              size={index === 0 ? 'large' : 'small'}
              listing={node}
              fullSlug={node.fullSlug}
              hasBottomSpacer={!isLastItem}
            />
          )
        })}
        <SpacerComponent bottom='xl2' />
        <Grid.Item
          justifySelfCenter
          alignSelfCenter
          columnStart={1}
          columnEnd={13}
        >
          <ButtonsWrapper>
            {Array.from({ length: numberOfPages }).map((_, index) => {
              const page = index + 1
              const link = page === 1 ? '/blog' : `/blog/page/${page}`

              return (
                <StyledLink to={link}>
                  <PageButton active={currentPage === page}>
                    <Caption>{page}</Caption>
                  </PageButton>
                </StyledLink>
              )
            })}
          </ButtonsWrapper>
        </Grid.Item>
      </SectionComponent>
    </ThemeProvider>
  )
}

export default BlogListings

export const pageQuery = graphql`
  query ListBlogPosts($skip: Int!, $limit: Int!) {
    allContentfulBlog(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        slug
        title
        date(formatString: "Do MMMM, YYYY")
        image {
          title
          file {
            url
          }
        }
      }
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.lg};
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
const PageButton = styled.div<{ active: boolean }>`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: solid 2px;
  & p {
    letter-spacing: initial;
    color: ${({ active }) =>
      active ? 'var(--color-accent)' : 'var(--color-text)'};
  }
  border-color: ${({ active }) =>
    active ? 'var(--color-accent)' : 'var(--color-text)'};
`
