import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components/macro'

// Components
import { ImageComponent } from '@components/ImageComponent'
import SpacerComponent from '@components/SpacerComponent'
import { Caption, Heading2 } from '@components/Typography'
import Grid from '@components/ViewGridComponent'

// Hooks
import useIsMobile from '@hooks/useIsMobile'

// Query
import { ListThreeBlogPostsQuery } from '../../API'

// Types
type Listing = ListThreeBlogPostsQuery['allContentfulBlog']['nodes'][0]

export type BlogListingComponentProps = {
  size: 'large' | 'small'
  listing: Listing
  /* So the pillar pages can display on the blog */
  fullSlug?: string
  hasBottomSpacer?: boolean
}

const BlogListingComponent: React.FC<BlogListingComponentProps> = props => {
  const isMobile = useIsMobile()

  return (
    <Grid.Item column={isMobile ? '3 / 13' : '3 / 12'}>
      <StyledLink
        to={
          props.fullSlug ? `/${props.fullSlug}` : `/blog/${props.listing.slug}`
        }
      >
        <Grid.SubGrid>
          <Grid.Item column={isMobile ? '1 / 13' : '1 / 6'}>
            <Image
              src={props.listing.image.file.url}
              alt={props.listing.image.title}
            />
            <SpacerComponent bottom={isMobile ? 'sm' : '0'} />
          </Grid.Item>
          <Grid.Item column={isMobile ? '1 / 11' : '7 / 13'}>
            <Heading2>{props.listing.title}</Heading2>

            <Grid.Item column='2 / -2'>
              <SpacerComponent bottom='sm' />
            </Grid.Item>
            <Time>{props.listing.date}</Time>
          </Grid.Item>
        </Grid.SubGrid>
      </StyledLink>
      {props.hasBottomSpacer && (
        <Grid.Item column='2 / -2'>
          <SpacerComponent bottom='xl' />
        </Grid.Item>
      )}
    </Grid.Item>
  )
}

export default BlogListingComponent

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  & img {
    transition: transform 0.5s ease-in-out;
  }

  &:hover {
    & img {
      transform: scale(1.1);
    }
    & h3 {
      color: var(--color-accent);
    }
  }
`

const Time = styled(Caption).attrs({ as: 'time' })`
  color: ${props => props.theme.colors.highlight.text};
`

const Image = styled(ImageComponent)`
  max-height: 230px;
  object-fit: cover;
`
